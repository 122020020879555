@import "../../scss/default/color_variable";
._site_header {
  position: relative;
  z-index: 101;

  ._btn._style6 {
    font-size: 16px;
  }
}

._site-branding {
  display: inline-block;
  max-width: 180px;
}
._site_header {
  position: absolute;
  justify-content: space-between;
  width: 100%;
  left: 0;
  top: 10px;

  transition: all 0.4s ease;
}
._site_header._style1 {
  ._main_header_in,
  ._top_header_in {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
  }
  ._main_header_right {
    display: flex;
    align-items: center;

    height: 100%;
  }
}

._site_header._style1 {
  &._sticky-active {
    background-color: $white;
    box-shadow: 0 10px 10px -10px rgba(33, 43, 53, 0.1);
  }

  ._icon_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
    cursor: pointer;
    border: 2px solid currentColor;
    border-radius: 50%;
    color: $white;
    ._icon_btn_in {
      width: 18px;
      height: 16px;
      line-height: 0;
      cursor: pointer;
      position: relative;
      text-align: left;
      overflow: hidden;
      span {
        display: inline-block;
        position: absolute;
        height: 2px;
        width: 100%;
        border-radius: 0;
        background-color: currentColor;
        color: inherit;
        vertical-align: top;
        transition: 0s ease-in-out;
        border-radius: 3px;
        &:nth-child(1) {
          transform: translate(0, -7px);
          width: calc(100% - 4px);
        }
        &:nth-child(2) {
          transform: translate(0, 0);
          width: calc(100% - 4px);
        }
        &:nth-child(3) {
          transform: translate(0, 7px);
        }
        &:nth-child(4) {
          transform: translate(0, 14px);
        }
      }
    }
    &:hover {
      ._icon_btn_in {
        span {
          transition: 0.3s ease-in-out;
          &:nth-child(1) {
            transform: translate(0, 0);
          }
          &:nth-child(2) {
            transform: translate(0, 7px);
            width: 100%;
          }
          &:nth-child(3) {
            transform: translate(0, 14px);
          }
          &:nth-child(4) {
            transform: translate(0, 21px);
          }
        }
      }
    }
  }

  ._action_box {
    display: flex;
    align-items: center;
    ._action_value {
      margin-left: 15px;
    }
    > * {
      &:not(:last-child) {
        margin-right: 35px;
      }
    }
  }
  ._btn {
    padding: 13px 15px;
  }
}

._site_header_full_width .container {
  max-width: 100%;
  padding: 0 115px;
}

._site_header_style1 {
  border-bottom: 1px solid #1a188d;

  ._main_header_right {
    display: flex;
    align-items: center;
  }

  ._nav + ._header_toolbox._center {
    margin-left: 35px;
  }

  ._nav ._nav_list > li.current-menu-item > a:before {
    bottom: -4px;
    background-color: $white;
  }
}
._side_header {
  position: fixed;
  top: 0;
  right: 0px;
  width: 100%;
  z-index: 1000;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  ._side_header_overlay {
    background-color: #121212;
    height: inherit;
    width: inherit;
    left: inherit;
    top: inherit;
    position: inherit;
    cursor: zoom-out;
    transition: all 0.4s ease;
    opacity: 0;
  }
  ._side_header_in {
    width: 500px;
    height: 100vh;
    background-color: #121212;
    flex: none;
    margin-left: auto;
    overflow: auto;
    padding: 90px 50px 50px 50px;
    position: relative;
    z-index: 2;
    right: -500px;
    transition: all 0.4s ease;
  }
  ._side_header_heading {
    font-size: 22px;
    line-height: 1.4em;
    margin: 0;
  }
  ._side_header_title {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  ._side_header_box {
    padding: 35px 0;
  }
  ._close {
    opacity: 0;
  }
  &.active {
    opacity: 1;
    visibility: visible;
    ._side_header_overlay,
    ._close {
      opacity: 1;
    }
    ._side_header_in {
      right: 0;
    }
  }
  ._side_header_shape {
    height: 257px;
    width: 257px;
    overflow: hidden;
    position: absolute;
    pointer-events: none;
    z-index: -1;
    bottom: 0;
    right: 0;
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      right: -90px;
      bottom: -80px;
      overflow: hidden;
      background-color: #121212;
      border-radius: 50%;
    }
  }
}
._close {
  position: fixed;
  right: 30px;
  top: 30px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 2px solid currentColor;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  z-index: 2;
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 50%;
    height: 2px;
    background-color: currentColor;
    left: 50%;
    top: 50%;
    margin-left: -25%;
    margin-top: -1px;
    transform: rotate(-45deg);
  }
  &::before {
    transform: rotate(45deg);
  }
  &:hover {
    color: $accent;
    transform: scale(1.05);
  }
}
@media screen and (max-width: 1199px) {
  ._main_header .container {
    max-width: 100%;
  }

  ._site_header._style1 {
    ._nav {
      display: flex;
    }
  }
}

._nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

._sticky_header {
  position: fixed !important;
  width: 100%;
  z-index: 999;
  transition: all 0.4s ease;
}
._sticky_header_active {
  top: 0px;
  background-color: #121212;
}

// ._gescout_show {
//   top: 0;
//   opacity: 1;
// }

._site_branding {
  display: inline-block;
  img {
    max-height: 45px;
  }
}

@media screen and (min-width: 1200px) {
  ._main_header {
    position: relative;

    .container-fluid {
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  ._main_header_center,
  ._top_header_center {
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 150px;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
  }

  ._site_header._style1 {
    ._main_header_center {
      display: flex;
      justify-content: right;
      align-items: right;
      height: 100%;
      width: 100%;
      max-width: calc(100% - 300px);
    }
    ._main_header_left {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ._nav {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 15px;
    line-height: 1.6em;
    color: $white;
    justify-content: space-around;
    position: absolute;
    right: 0;

    ._nav_list {
      display: flex !important;
      flex-wrap: wrap;
      height: inherit;

      > li {
        margin-right: 50px;
        height: inherit;

        &:last-child {
          margin-right: 0;
        }

        > a {
          padding: 10px 0;
          display: inline-flex;
          position: relative;
          height: inherit;
          align-items: center;
        }

        > ul {
          left: 0;
          top: calc(100% + 15px);
        }

        &:hover {
          > ul {
            top: 100%;
            opacity: 1;
            visibility: visible;
            transition: all 0.4s ease;
          }
        }
        &.menu-item-has-children {
          > a {
            position: relative;
            &::after {
              content: "";
              display: inline-block;
              height: 8px;
              width: 8px;
              border: 2px solid currentColor;
              transform: rotate(45deg);
              border-left: 0;
              border-top: 0;
              margin-left: 6px;
              position: relative;
              top: -2px;
              border-radius: 0px 0px 2px 0px;
            }
          }
        }
      }

      li:not(._mega-menu) {
        position: relative;
      }

      ul {
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: center;
        // width: 260px;
        background-color: #121212;
        position: absolute;
        box-shadow: 0px 1px 2px 0px rgba(2, 0, 181, 0.1);
        // border-top: 2px solid $accent;
        // padding: 10px 0;
        z-index: 100;
        opacity: 0;
        visibility: hidden;
        display: block !important;
        border-radius: 8px 8px 8px 8px;
        transition: all 0.1s ease;

        li {
          &:hover {
            ul {
              top: 0px;
            }

            > ul {
              opacity: 1;
              visibility: visible;
              transition: all 0.4s ease;
            }
          }
        }

        a {
          font-size: 90%;
          display: block;
          line-height: inherit;
          padding: 10px 20px;
        }

        ul {
          top: 15px;
          left: 100%;
        }
      }
      .active {
        color: $accent;
      }
    }
  }
  ._menu_toggle,
  ._menu_dropdown_toggle {
    display: none;
  }

  ._nav ._nav_list {
    ._mega-wrapper {
      width: 1170px !important;
      left: 50%;
      transform: translateX(-50%);
      display: flex !important;
      position: absolute;
      padding: 0;

      > li {
        flex: 1;
        // padding: 10px 0;

        &:not(:last-child) {
          border-right: 2px solid $border;
        }

        > a {
          color: $primary;
          font-weight: 600;

          &:hover {
            background-color: transparent;
          }
        }

        ul {
          position: initial;
          border: none;
          padding: 0;
          width: 100%;
          box-shadow: none;
          background-color: transparent;
        }
      }
    }

    ._mega-menu {
      &:hover {
        ._mega-wrapper {
          li ul {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  ._nav ._nav_list > li ul:not(._mega-wrapper) .menu-item-has-children > a {
    position: relative;
  }
}

@media screen and (max-width: 1360px) {
  ._site-branding {
    max-width: 190px;
  }
}

@media screen and (max-width: 1199px) {
  ._menu_dropdown_toggle {
    position: absolute;
    height: 30px;
    width: 30px;
    right: 15px;
    top: 5px;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 2px;
      width: 10px;
      background-color: $secondary;
      transition: all 0.3s ease;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &.active {
      &:before {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }

  ._nav {
    ._nav_list {
      position: absolute;
      width: 100vw;
      left: -12px;
      background-color: #181818;
      padding: 10px 0;
      display: none;
      top: 100%;
      border-top: 1px solid #4d4d4d52;
      border-bottom: 1px solid #4d4d4d52;
      overflow: auto;
      max-height: calc(100vh - 80px);
      line-height: 1.6em;

      ul {
        padding-left: 15px;
        display: none;
      }
      ._menu_dropdown_toggle.active + ul {
        display: block;
      }

      a {
        display: block;
        padding: 8px 20px;
      }
    }

    .menu-item-has-children {
      position: relative;
    }
  }

  /*Mobile Menu Button*/
  ._munu_toggle {
    display: inline-block;
    width: 30px;
    height: 27px;
    cursor: pointer;
    position: absolute;
    top: 27px;
    right: 30px;

    span,
    span:before,
    span:after {
      width: 100%;
      height: 2px;
      background-color: currentColor;
      display: block;
    }

    span {
      margin: 0 auto;
      position: relative;
      top: 12px;
      transition-duration: 0s;
      transition-delay: 0.2s;

      &:before {
        content: "";
        position: absolute;
        margin-top: -9px;
        transition-property: margin, transform;
        transition-duration: 0.2s;
        transition-delay: 0.2s, 0s;
      }

      &:after {
        content: "";
        position: absolute;
        margin-top: 9px;
        transition-property: margin, transform;
        transition-duration: 0.2s;
        transition-delay: 0.2s, 0s;
      }
    }
  }

  ._site_header._style1 ._munu_toggle {
    top: 50%;
    right: 0px;
    margin-top: -13px;
  }

  ._toggle_active {
    span {
      background-color: rgba(0, 0, 0, 0);
      transition-delay: 0.2s;

      &:before {
        margin-top: 0;
        transform: rotate(45deg);
        transition-delay: 0s, 0.2s;
      }

      &:after {
        margin-top: 0;
        transform: rotate(-45deg);
        transition-delay: 0s, 0.2s;
      }
    }
  }

  ._header_toolbox {
    margin-right: 50px;
  }

  ._nav ._nav_list a {
    position: relative;
  }

  ._site_header._style1 ._main_header_in {
    height: 80px;
    display: flex;
    justify-content: space-between;
  }

  ._hamburger span {
    height: 3px;
  }

  ._site_header .current-menu-item > a:before {
    display: none;
  }

  ._site_header._style1._type4 {
    ._main_header_in {
      display: flex;
      justify-content: space-between;
      padding: 0;
      box-shadow: none;
      border-radius: 0px;
      margin-top: 0;
      background-color: transparent;
    }
  }
  ._site_header._style1 ._main_header_center {
    ._site_branding {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  ._site_header._style1 {
    top: 0;
  }
  ._toolbox {
    margin-right: 45px;
  }
  ._site_header._style1 ._icon_btn {
    width: 38px;
    height: 38px;
  }
  ._toolbox,
  ._side_header {
    display: none;
  }
  ._site_header_full_width .container {
    max-width: 100%;
    padding: 0 15px;
  }
}

@media screen and (max-width: 991px) {
  ._site_header .container {
    max-width: 100%;
  }
  ._site_header._style1 {
    ._action_box > *:not(:last-child) {
      margin-right: 25px;
    }
  }
  ._site_header._style1 ._btn {
    padding: 8px;
  }
}
@media screen and (max-width: 575px) {
  ._site-branding {
    max-width: 150px;
  }

  ._hamburger_wrap ._hamburger {
    margin-right: 0;
  }
  ._site_branding img {
    max-height: 32px;
  }
  ._site_header._style1 ._btn {
    span {
      display: none;
    }
    svg {
      margin-right: 0;
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 450px) {
}
