@import "../../scss/default/color_variable";

.back-color {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    
  );
  // background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  // background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  // background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
}

._cta._style1 {
  padding: 90px 30px;
  border-radius: 15px;
  ._cta_in {
    position: relative;
    z-index: 2;
  }
  ._cta_title {
    font-weight: 600;
    font-size: 50px;
  }
}
@media screen and (max-width: 1300px) {
  ._cta._style1 ._cta_title {
    font-size: 42px;
  }
}
@media screen and (max-width: 991px) {
  ._cta._style1 {
    padding: 70px 30px;
    border-radius: 15px;
    ._cta_title {
      font-size: 36px;
      br {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  ._cta._style1 {
    br {
      display: none;
    }
  }
}
@media screen and (max-width: 575px) {
  ._cta._style1 {
    padding: 70px 20px;
    ._cta_title {
      font-size: 28px;
    }
  }
}