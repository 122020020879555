@import "../../scss/default/color_variable";

._social_btns._style1 {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  a {
    height: 36px;
    width: 36px;
    border-radius: 50%;

    margin: 7px;
    color: $white;
    &:hover {
      border-color: $accent;
      color: $accent;
      transform: scale(1.5);
      svg {
        fill: $accent;
      }
    }
  }
}

.bottom_footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin-top:20px ;
  border-top: 2px solid $black;
  > * {
    padding: 4px 0;
  }
}

.text_widget + .social_btns.style1 {
  margin-top: 25px;
}

.footer_main {
  padding: 60px 0 15px;
}

@media screen and (max-width: 767px) {
  .bottom_footer {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
}

.copyright{
  color: $white;
}