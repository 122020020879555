/* - Preloader */

.preloader {

    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 100;

}



.preloader:before {
  content: "";
  
  position: absolute;
//   left: -10vw;
//   top: -20vh;
  width: 120vw;
  height: 120vh;
  background: #fff;
}

.preloader .spinner {
  
  width: 15px;
  height: 15px;
  background: #ffba08;
  border-radius: 15px;
  -webkit-animation: spinner 0.75s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
  animation: spinner 0.75s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

.preloader .spinner-logo {
    
  position: relative;
  margin: 0 auto;
  width: 250px;
  font-size: 0;
}

.preloader .spinner-logo img {
    display: flex;
    align-items: center;
    justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  max-width: 100%;
}

.preloader .spinner.spinner-line {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #ffba08;
  border-radius: 0;
  -webkit-animation: spinnerline 1s cubic-bezier(0.17, 0.37, 0.43, 0.67)
    infinite;
  animation: spinnerline 1s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

.preloader .spinner-dot {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0;
}

.preloader.loaded:before {
  -webkit-animation: preloader 0.75s cubic-bezier(0.3, 0, 0.3, 1) forwards;
  animation: preloader 0.75s cubic-bezier(0.3, 0, 0.3, 1) forwards;
}

@-webkit-keyframes preloader {
  0% {
    -webkit-transform: translate3d(0, 0, 0) skew(-10deg, -10deg);
    transform: translate3d(0, 0, 0) skew(-10deg, -10deg);
  }
  100% {
    -webkit-transform: translate3d(0, -100%, 0) skew(0deg);
    transform: translate3d(0, -100%, 0) skew(0deg);
  }
}

@-webkit-keyframes spinner {
  0% {
    width: 15px;
  }
  50% {
    width: 50px;
  }
  100% {
    width: 15px;
  }
}

@keyframes spinner {
  0% {
    width: 15px;
  }
  50% {
    width: 50px;
  }
  100% {
    width: 15px;
  }
}

@-webkit-keyframes spinnerline {
  0% {
    width: 0;
    left: 0;
    right: auto;
  }
  50% {
    width: 100%;
    left: 0;
    right: auto;
  }
  51% {
    width: 100%;
    left: auto;
    right: 0;
  }
  100% {
    width: 0;
    left: auto;
    right: 0;
  }
}

@keyframes spinnerline {
  0% {
    width: 0;
    left: 0;
    right: auto;
  }
  50% {
    width: 100%;
    left: 0;
    right: auto;
  }
  51% {
    width: 100%;
    left: auto;
    right: 0;
  }
  100% {
    width: 0;
    left: auto;
    right: 0;
  }
}
