@import '../../scss/default/color_variable';
._cursor_lg,
._cursor_sm {
  position: fixed;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: hard-light;
  z-index: 9999;
}
._cursor_lg {
  width: 40px;
  height: 40px;
  border: 1px solid $accent;
  left: 0;
  top: 0;
  pointer-events: none;
  transition: 0.01s;
}
._cursor_sm {
  width: 8px;
  height: 8px;
  background-color: $accent;
  left: 15px;
  top: 15px;
  pointer-events: none;
  transition: 0.1s;
}
@media screen and (max-width: 575px) {
  ._cursor_lg,
  ._cursor_sm {
    display: none;
  }
}

