@media screen and (min-width: 992px) {
  ._height_0 {
    height: 0px;
  }

  ._height_5 {
    height: 5px;
  }

  ._height_10 {
    height: 10px;
  }

  ._height_15 {
    height: 15px;
  }

  ._height_20 {
    height: 20px;
  }

  ._height_25 {
    height: 25px;
  }

  ._height_30 {
    height: 30px;
  }

  ._height_35 {
    height: 35px;
  }

  ._height_40 {
    height: 40px;
  }

  ._height_45 {
    height: 45px;
  }

  ._height_50 {
    height: 50px;
  }

  ._height_55 {
    height: 55px;
  }

  ._height_60 {
    height: 60px;
  }

  ._height_65 {
    height: 65px;
  }

  ._height_70 {
    height: 70px;
  }

  ._height_75 {
    height: 75px;
  }

  ._height_80 {
    height: 80px;
  }

  ._height_85 {
    height: 85px;
  }

  ._height_90 {
    height: 90px;
  }

  ._height_95 {
    height: 95px;
  }

  ._height_100 {
    height: 100px;
  }

  ._height_105 {
    height: 105px;
  }

  ._height_110 {
    height: 110px;
  }

  ._height_115 {
    height: 115px;
  }

  ._height_120 {
    height: 120px;
  }

  ._height_125 {
    height: 125px;
  }

  ._height_130 {
    height: 130px;
  }

  ._height_135 {
    height: 135px;
  }

  ._height_140 {
    height: 140px;
  }

  ._height_145 {
    height: 145px;
  }

  ._height_150 {
    height: 150px;
  }

  ._height_155 {
    height: 155px;
  }

  ._height_160 {
    height: 160px;
  }

  ._height_165 {
    height: 165px;
  }

  ._height_170 {
    height: 170px;
  }

  ._height_175 {
    height: 175px;
  }

  ._height_180 {
    height: 180px;
  }

  ._height_185 {
    height: 185px;
  }

  ._height_190 {
    height: 190px;
  }

  ._height_195 {
    height: 195px;
  }

  ._height_200 {
    height: 200px;
  }

  ._height_205 {
    height: 205px;
  }

  ._height_210 {
    height: 210px;
  }
}

@media screen and (max-width: 991px) {
  ._height_lg_0 {
    height: 0px;
  }

  ._height_lg_5 {
    height: 5px;
  }

  ._height_lg_10 {
    height: 10px;
  }

  ._height_lg_15 {
    height: 15px;
  }

  ._height_lg_20 {
    height: 20px;
  }

  ._height_lg_25 {
    height: 25px;
  }

  ._height_lg_30 {
    height: 30px;
  }

  ._height_lg_35 {
    height: 35px;
  }

  ._height_lg_40 {
    height: 40px;
  }

  ._height_lg_45 {
    height: 45px;
  }

  ._height_lg_50 {
    height: 50px;
  }

  ._height_lg_55 {
    height: 55px;
  }

  ._height_lg_60 {
    height: 60px;
  }

  ._height_lg_65 {
    height: 65px;
  }

  ._height_lg_70 {
    height: 70px;
  }

  ._height_lg_75 {
    height: 75px;
  }

  ._height_lg_80 {
    height: 80px;
  }

  ._height_lg_85 {
    height: 85px;
  }

  ._height_lg_90 {
    height: 90px;
  }

  ._height_lg_95 {
    height: 95px;
  }

  ._height_lg_100 {
    height: 100px;
  }

  ._height_lg_105 {
    height: 105px;
  }

  ._height_lg_110 {
    height: 110px;
  }

  ._height_lg_115 {
    height: 115px;
  }

  ._height_lg_120 {
    height: 120px;
  }

  ._height_lg_125 {
    height: 125px;
  }

  ._height_lg_130 {
    height: 130px;
  }

  ._height_lg_135 {
    height: 135px;
  }

  ._height_lg_140 {
    height: 140px;
  }

  ._height_lg_145 {
    height: 145px;
  }

  ._height_lg_150 {
    height: 150px;
  }

  ._height_lg_155 {
    height: 155px;
  }

  ._height_lg_160 {
    height: 160px;
  }

  ._height_lg_165 {
    height: 165px;
  }

  ._height_lg_170 {
    height: 170px;
  }

  ._height_lg_175 {
    height: 175px;
  }

  ._height_lg_180 {
    height: 180px;
  }

  ._height_lg_185 {
    height: 185px;
  }

  ._height_lg_190 {
    height: 190px;
  }

  ._height_lg_195 {
    height: 195px;
  }

  ._height_lg_200 {
    height: 200px;
  }

  ._height_lg_205 {
    height: 205px;
  }

  ._height_lg_210 {
    height: 210px;
  }
}
