._partner_logo_wrap {
  display: flex;
  margin-bottom: 0%;
  // justify-content: space-evenly;
  // margin: 0 -15px;
  // object-fit: contain;
  // height: 200px;
  width: 100%;
}
._partner_logo {
  padding: 15px 15px;
  height: auto;
  width: 200px;
  justify-content: center;

}


@media screen and (max-width: 768px) {
  ._partner_logo_wrap {
    margin: 0 -10px;
    object-fit: contain;
  height: 200px;
  width: 200px;
  }
}

@media screen and (max-width: 768px) {
._partner_logo {
  padding: 10px 10px;
  height: auto;
  width: 50px;
  justify-content: center;

}}
